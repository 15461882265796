/* ----------------- Estilos para el buzon de sugerencias ------------------------ */

#buscarSugerencias {
	.fieldInline {
		display: inline;
		margin-right: 1em;
		margin-bottom:1em;

		.mobile & {display: block;}
	}
}

.EmailOculto {
    display: none !important;
}



.sugerencias {	
	
	&_config {
		display: flex;
		flex-flow: row wrap;
		gap: 2rem;

		> * {
			display: flex;
			flex-direction: column;
		}
	}

	&_search {

		.card {

			&-body {
				background-color: var(--clr-primary-light);
			}
	
		}
	}
}

.informeSugerencias {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	li {
		a {
			font-weight: bold;
		}
	}
}